import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import PracticeTypeSelector from "../PracticeTypeSelector";
import ProvinceSelector from "./form-components/ProvinceSelector";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TermsAndConditions from "../terms/termsAndConditions";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import CountrySelector from "./form-components/CountrySelector";

function SignUp({ navigateAfterSignInOrSignUp }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  //only use to display TOS not agreed, or passwords not matching
  const [errorText, setErrorText] = useState("");
  //loading state used to disable Sign Up button to prevent multiple registrations
  const [loading, setLoading] = useState(false);
  //states to open Terms of Service Modal
  const [openTOS, setOpenTOS] = useState(false);
  const [acceptedTOS, setAcceptedTOS] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [noLicenseNumber, setNoLicenseNumber] = useState(false);
  const [noLicenseReason, setNoLicenseReason] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("Canada");
  const [practiceType, setPracticeType] = useState("");
  const [profession, setProfession] = useState("");
  const [company, setCompany] = useState("");
  const [otherCompany, setOtherCompany] = useState("");

  //toggle making typed s password viewable to user
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { signUp } = useAuth();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  //no license number checkbox
  const handleNoLicenseCheckbox = (event) => {
    setNoLicenseNumber(event.target.checked);
    setLicenseNumber(event.target.checked ? "N/A" : "");
    if (!event.target.checked) {
      setNoLicenseReason("");
    }
  };

  //Regex to check valid format of email
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  //Regex to validate strong password was entered.
  //Criteria at least: 8 chars, one lowercase, one uppercase, one digit, and one special character
  const strongPasswordRegex =
    /^(?=.*\d)(?=.*[!@#$%^&*./?])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorText("");
    setError(false);

    try {
      //check if input fields are not empty
      if (!firstName.trim()) {
        return setError(true);
      }

      if (!lastName.trim()) {
        return setError(true);
      }

      if (!email.trim()) {
        return setError(true);
      }

      //check format of email
      if (!emailRegex.test(email)) {
        setError(true);
        setErrorText(t("signUp.errorInvalidEmailFormat"));
        return;
      }

      if (!password.trim()) {
        return setError(true);
      }

      if (!confirmPassword.trim()) {
        return setError(true);
      }

      //check that passwords are matching
      if (password !== confirmPassword) {
        setError(true);
        setErrorText(t("signUp.errorPasswordsNotMatching"));
        return;
      }

      //check that password strength is strong
      if (!strongPasswordRegex.test(password)) {
        setError(true);
        setErrorText(t("signUp.errorPasswordCriteria"));
        return;
      }

      if (!licenseNumber.trim() && !noLicenseNumber) {
        return setError(true);
      }

      if (!province) {
        return setError(true);
      }
      if (!country) {
        return setError(true);
      }
      //check that Terms and Conditions accepted
      if (!acceptedTOS) {
        setError(true);
        setErrorText(t("signUp.errorTermsNotAccepted"));
        return;
      }

      setLoading(true);
      //500ms delay to allow for loading animation
      await new Promise((resolve) => setTimeout(resolve, 500));
      await signUp(
        email,
        password,
        firstName.trim(),
        lastName.trim(),
        licenseNumber.trim(),
        province,
        practiceType,
        // if otherCompany value exists, send that value for company value
        otherCompany ? otherCompany : company,
        profession,
        country,
        noLicenseReason
      );
      setError("");
      window.gtag_report_conversion();
      //bring to dashboard page after sign up
      navigateAfterSignInOrSignUp();
    } catch (err) {
      setLoading(false);
      if (err.code === "auth/email-already-in-use") {
        // handle email already in use error
        setErrorText(t("signUp.errorEmailInUse"));
      }
      setError(err.message || t("signUp.errorAccountCreationFailed"));
    } finally {
      setLoading(false);
    }
  };

  const paperStyle = { padding: "30px 20px", margin: "20px auto" };
  const headerStyle = { margin: 0 };
  const fieldStyle = {
    marginTop: 10,
  };

  return (
    <>
      <Grid>
        <Paper elevation={20} style={paperStyle}>
          <Grid align="center">
            <h2 style={headerStyle}>{t("signUp.title")}</h2>{" "}
            <Typography variant="caption" gutterBottom>
              {t("signUp.caption")}
              {errorText && <Typography color="error">{errorText}</Typography>}
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                error={!firstName.trim() && error}
                fullWidth
                label={t("signUp.firstNameLabel")}
                placeholder={t("signUp.firstNamePlaceholder")}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={fieldStyle}
                helperText={
                  !firstName.trim() && error
                    ? t("signUp.errorFieldRequired")
                    : ""
                }
              />

              <TextField
                error={!lastName.trim() && error}
                fullWidth
                label={t("signUp.lastNameLabel")}
                placeholder={t("signUp.lastNamePlaceholder")}
                helperText={
                  !lastName.trim() && error
                    ? t("signUp.errorFieldRequired")
                    : ""
                }
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={fieldStyle}
              />
            </Box>
            <TextField
              label={t("signUp.emailLabel")}
              placeholder={t("signUp.emailPlaceholder")}
              helperText={!email && error ? t("signUp.errorFieldRequired") : ""}
              error={!email && error}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={fieldStyle}
            />
            <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
              <InputLabel>{t("signUp.passwordLabel")}</InputLabel>

              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("signUp.togglePasswordVisibility")}
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("signUp.passwordLabel")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                placeholder={t("signUp.passwordPlaceholder")}
                error={!password && error}
                helperText={
                  !password && error ? t("signUp.errorFieldRequired") : ""
                }
              />
            </FormControl>
            <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                {t("signUp.confirmPasswordLabel")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("signUp.confirmPasswordPlaceholder")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("signUp.toggleConfirmPasswordVisibility")}
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("signUp.confirmPasswordLabel")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                error={!confirmPassword && error}
                helperText={
                  !confirmPassword && error
                    ? t("signUp.errorFieldRequired")
                    : ""
                }
              />
            </FormControl>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <ProvinceSelector
                  error={error}
                  setProvince={setProvince}
                  province={province}
                  country={country}
                />
                <CountrySelector
                  error={error}
                  setCountry={setCountry}
                  country={country}
                />
              </Box>
              <PracticeTypeSelector
                practiceType={practiceType}
                setPracticeType={setPracticeType}
                setCompany={setCompany}
                company={company}
                profession={profession}
                setProfession={setProfession}
                setOtherCompany={setOtherCompany}
                otherCompany={otherCompany}
                error={error}
                country={country}
              />
              <TextField
                error={!licenseNumber && error && !noLicenseNumber}
                fullWidth
                label={t("signUp.licenseNumberLabel")}
                placeholder={t("signUp.licenseNumberPlaceholder")}
                value={licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                style={{ marginTop: 10 }}
                disabled={noLicenseNumber}
                helperText={
                  !licenseNumber && error && !noLicenseNumber
                    ? t("signUp.errorFieldRequired")
                    : ""
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noLicenseNumber}
                    onChange={handleNoLicenseCheckbox}
                    color="primary"
                  />
                }
                label={t("signUp.noLicenseNumber")}
              />

              {/* Conditional rendering for no license reason */}
              {noLicenseNumber && (
                <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
                  <InputLabel id="no-license-reason">
                    {t("signUp.noLicenseReasonLabel")}
                  </InputLabel>
                  <Select
                    labelId="no-license-reason"
                    value={noLicenseReason}
                    onChange={(e) => setNoLicenseReason(e.target.value)}
                    label={t("signUp.noLicenseReasonLabel")}
                  >
                    <MenuItem value={"Student"}>
                      {t("signUp.noLicenseReason.student")}
                    </MenuItem>
                    <MenuItem value={"International Graduate"}>
                      {t("signUp.noLicenseReason.internationalGraduate")}
                    </MenuItem>
                    <MenuItem value={"Other"}>
                      {t("signUp.noLicenseReason.other")}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptedTOS"
                    checked={acceptedTOS}
                    onChange={(event) => setAcceptedTOS(event.target.checked)}
                    style={{ margin: 0, padding: 0 }}
                  />
                }
                label={t("signUp.termsConditions")}
                error={!acceptedTOS && error}
                sx={{ mt: 2 }}
                style={{
                  color: !acceptedTOS && error ? "#f44336" : undefined,
                  margin: 0,
                  padding: 0,
                }}
              />
              <p
                onClick={() => setOpenTOS(true)}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  margin: "0",
                  paddingLeft: "5px",
                }}
              >
                {" "}
                {t("signUp.t&c")}
              </p>
            </div>

            <button
              className="new-button-style primary teal-hover"
              style={{
                width: "100%",
                height: "50px",
                fontSize: "18px",
                margin: "20px 0px",
              }}
              disabled={loading}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                t("signUp.signUpButton")
              )}{" "}
            </button>
          </form>
        </Paper>
      </Grid>
      <Dialog open={openTOS} onClose={() => setOpenTOS(false)}>
        <DialogTitle>{t("signUp.dialogTitle")}</DialogTitle>{" "}
        <DialogContent>
          <DialogContentText>
            <TermsAndConditions />
          </DialogContentText>{" "}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SignUp;
