import React, { useState, useEffect } from "react";
import {
  addQuestion,
  getQuestions,
  addReply,
  editQuestion,
  deleteQuestion,
  editReply,
  deleteReply,
} from "../../helpers/source";
import { useUser } from "../../../contexts/UserProvider";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  TextField,
  IconButton,
  Card,
  CardContent,
  Box,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import SchoolIcon from "@mui/icons-material/School";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Tooltip from "@mui/material/Tooltip";

import "./Forum.scss";

function Forum({
  courseId,
  sections,
  currentModule,
  onCommentAdded,
  vimeoTimestamp,
  videoId,
}) {
  const { userObject } = useUser();
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newReply, setNewReply] = useState({});
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [editReplyId, setEditReplyId] = useState({});
  const [editReplyContent, setEditReplyContent] = useState("");
  // State to manage question content being edited
  const [editQuestionContent, setEditQuestionContent] = useState("");
  const [replyVisibility, setReplyVisibility] = useState({});
  const [replyInputVisibility, setReplyInputVisibility] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      const fetchedQuestions = await getQuestions(courseId);
      // Filter questions by the current module
      const filteredQuestions = fetchedQuestions.filter(
        (question) => question.sectionId === sections[currentModule].id
      );
      setQuestions(filteredQuestions);

      //set replyVisibility to true for all questions at start
      const initialReplyVisibility = {};
      filteredQuestions.forEach((question) => {
        initialReplyVisibility[question.id] = true; // Set all replies to visible
      });
      setReplyVisibility(initialReplyVisibility);
    };

    fetchQuestions();
  }, [courseId, currentModule, sections]);

  // function to add a whole new comment/question
  const toggleReplyVisibility = (questionId) => {
    setReplyVisibility((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  const toggleReplyInputVisibility = (questionId) => {
    setReplyInputVisibility((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));

    // Automatically show replies when a user starts to reply
    setReplyVisibility((prev) => ({
      ...prev,
      [questionId]: true,
    }));
  };

  const handleAddQuestion = async () => {
    if (newQuestion.trim()) {
      await addQuestion(
        courseId,
        userObject.uid,
        newQuestion,
        userObject.firstName,
        userObject.lastName,
        sections[currentModule].id, // Automatically associate with the current module
        userObject?.profession,
        userObject?.province,
        vimeoTimestamp,
        videoId
      );
      setNewQuestion("");
      const fetchedQuestions = await getQuestions(courseId);
      const filteredQuestions = fetchedQuestions.filter(
        (question) => question.sectionId === sections[currentModule].id
      );
      setQuestions(filteredQuestions);

      // calling the callback when the question is added
      if (onCommentAdded) {
        onCommentAdded();
      }
    }
  };

  // function to add a REPLY to an original question/comment
  const handleAddReply = async (questionId) => {
    if (newReply[questionId]?.trim()) {
      //need questionId, userId, reply's content, and the replier's first and last name
      await addReply(
        questionId,
        userObject.uid,
        newReply[questionId],
        userObject.firstName,
        userObject.lastName,
        userObject?.instructor,
        userObject.profession,
        userObject.province
      );
      setNewReply((prev) => ({ ...prev, [questionId]: "" }));
      const fetchedQuestions = await getQuestions(courseId);

      // Reapply the filter after fetching questions
      const filteredQuestions = fetchedQuestions.filter(
        (question) => question.sectionId === sections[currentModule].id
      );

      setQuestions(filteredQuestions);

      // Ensure all replies are shown after adding a reply
      setReplyVisibility((prev) => ({
        ...prev,
        [questionId]: true,
      }));
    }
  };

  // after performing any edit or deletion, forum questions fetched again from db
  // ensures that what user sees is consistent with the current state in db

  /**** ----- functions to edit/delete questions/replies in forum ---- */
  const handleEditQuestion = async (questionId, newContent) => {
    await editQuestion(questionId, newContent);
    setEditQuestionId(null);

    const fetchedQuestions = await getQuestions(courseId);

    // reapply the filter after fetching questions so you only see questions posted in this module section
    const filteredQuestions = fetchedQuestions.filter(
      (question) => question.sectionId === sections[currentModule].id
    );

    setQuestions(filteredQuestions);
  };

  // function to delete a question
  const handleDeleteQuestion = async (questionId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (confirmed) {
      await deleteQuestion(questionId);
      const fetchedQuestions = await getQuestions(courseId);

      // Reapply the filter after fetching questions so you only see questions posted in this module section
      const filteredQuestions = fetchedQuestions.filter(
        (question) => question.sectionId === sections[currentModule].id
      );

      setQuestions(filteredQuestions);
    }
  };

  // function to edit a reply
  const handleEditReply = async (questionId, replyId, newContent) => {
    await editReply(questionId, replyId, newContent);
    setEditReplyId((prev) => ({ ...prev, [questionId]: null }));

    const fetchedQuestions = await getQuestions(courseId);

    // reapply filter after fetching questions so u only see questions posted on this module section
    const filteredQuestions = fetchedQuestions.filter(
      (question) => question.sectionId === sections[currentModule].id
    );

    setQuestions(filteredQuestions);
  };

  // function to delete a reply
  const handleDeleteReply = async (questionId, replyId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this reply?"
    );
    if (confirmed) {
      await deleteReply(questionId, replyId);
      const fetchedQuestions = await getQuestions(courseId);

      // Reapply the filter after fetching questions so you only see questions posted in this module section
      const filteredQuestions = fetchedQuestions.filter(
        (question) => question.sectionId === sections[currentModule].id
      );

      setQuestions(filteredQuestions);
    }
  };

  // handle edit reply click
  const handleEditReplyClick = (questionId, replyId, content) => {
    setEditReplyId((prev) => ({ ...prev, [questionId]: replyId }));
    setEditReplyContent(content);
  };

  const handleEditQuestionClick = (questionId, content) => {
    setEditQuestionId(questionId);
    setEditQuestionContent(content);
  };

  const formatTimestamp = (timestamp) => {
    // Convert Firebase timestamp to a JavaScript Date object
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    // Format using moment.js
    return moment(date).format("MMMM D, YYYY [at] h:mm:ss A");
  };

  return (
    <Container maxWidth="md">
      {/* accordion title */}
      <Accordion className="forum-accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="forum-header">
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">
              Course Q&A ({questions.length})
            </Typography>
            <Typography variant="body2" color="textSecondary">
              View questions submitted by other users
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {/* Directly display the Question Submission Form */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            sx={{ mb: "10px" }}
          >
            <TextField
              className="ask-question-textbox"
              label="Don’t see your question answered? Submit one yourself!"
              multiline
              maxRows={4}
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddQuestion} edge="end">
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box>
            {questions.map((question) => {
              // IF main question marked "deleted" and has NO replies, SKIP rendering it.
              // if it did have comments under it, keep it and say Comment Removed
              if (question.deleted && question.replies.length === 0) {
                return null;
              }

              return (
                <Card key={question.id} variant="outlined">
                  <CardContent className="question-content">
                    <Box display="flex" alignItems="center">
                      <Typography className="question-author" flexGrow={1}>
                        {question.deleted
                          ? "Comment removed"
                          : `${question.firstName} ${question.lastName.charAt(
                              0
                            )}`}
                        {/* display profession and province in less prominent font */}
                        {(question.profession || question.province) && (
                          <span className="author-details">
                            {question.profession
                              ? ` ${question.profession}`
                              : ""}
                            {question.profession && question.province
                              ? ", "
                              : ""}
                            {question.province ? question.province : ""}
                          </span>
                        )}
                      </Typography>

                      <Typography
                        style={{
                          color: "#888",
                          marginLeft: "6px",
                          fontSize: "0.875em", // Smaller font size
                        }}
                      >
                        {formatTimestamp(question.timestamp)}
                      </Typography>
                      {/* if user is looking at their OWN reply, display Delete/Edit buttons */}

                      {question.userId === userObject.uid &&
                        !question.deleted && (
                          <Box>
                            <IconButton
                              onClick={() =>
                                handleEditQuestionClick(
                                  question.id,
                                  question.content
                                )
                              }
                              size="small"
                              className="mui-icon"
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteQuestion(question.id)}
                              size="small"
                              className="mui-icon"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )}
                    </Box>
                    {/* Show the question content or "Comment removed" */}
                    {!question.deleted && editQuestionId === question.id ? (
                      <Box>
                        <TextField
                          label="Edit your question"
                          multiline
                          rows={4}
                          value={editQuestionContent}
                          onChange={(e) =>
                            setEditQuestionContent(e.target.value)
                          }
                          variant="outlined"
                          fullWidth
                        />
                        <Box mt={1}>
                          <IconButton
                            onClick={() =>
                              handleEditQuestion(
                                question.id,
                                editQuestionContent
                              )
                            }
                            size="small"
                            className="mui-icon"
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => setEditQuestionId(null)}
                            size="small"
                            className="mui-icon"
                          >
                            <CancelIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      !question.deleted && (
                        <Typography variant="body1">
                          {question.content}
                        </Typography>
                      )
                    )}
                    <div className="question-actions">
                      <a onClick={() => toggleReplyVisibility(question.id)}>
                        {replyVisibility[question.id] ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                        {replyVisibility[question.id]
                          ? `Hide Replies (${question.replies.length})`
                          : `View Replies (${question.replies.length})`}{" "}
                      </a>
                      <a
                        onClick={() => toggleReplyInputVisibility(question.id)}
                      >
                        Reply
                      </a>
                    </div>
                  </CardContent>
                  <CardContent>
                    <Box>
                      {replyVisibility[question.id] &&
                        question.replies.map((reply, index) => (
                          <Box
                            key={index}
                            pl={2}
                            mb={1}
                            borderLeft={1}
                            borderColor="grey.300"
                            className={`reply-content ${
                              reply.isInstructor ? "instructor-reply" : ""
                            }`}
                            sx={{
                              backgroundColor: reply.isInstructor
                                ? "#FFF8E1"
                                : "$grey", // Light yellow/goldish for instructors, otherwise use grey
                            }}
                          >
                            <Box display="flex" alignItems="center" mb={1}>
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  padding: "4px",
                                  flexGrow: 1, // This allows the name and icon to take up available space, pushing the timestamp and icons to the right
                                }}
                              >
                                <Typography
                                  className="reply-author"
                                  sx={{
                                    color: reply.isInstructor
                                      ? "#C5A600"
                                      : "$grey-dark",
                                    fontWeight: 800,
                                    fontSize: "1.1em",
                                  }}
                                >
                                  {reply.firstName} {reply.lastName.charAt(0)}
                                  {(reply.profession || reply.province) && (
                                    <span className="author-details">
                                      {reply.profession
                                        ? ` ${reply.profession}`
                                        : ""}
                                      {reply.profession && reply.province
                                        ? ", "
                                        : ""}
                                      {reply.province ? reply.province : ""}
                                    </span>
                                  )}
                                </Typography>
                                {reply.isInstructor && (
                                  <Tooltip title="Instructor" arrow>
                                    <Box display="flex" alignItems="center">
                                      <SchoolIcon
                                        sx={{
                                          color: "#C5A600",
                                          marginLeft: "8px",
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          color: "#C5A600",
                                          fontWeight: 600,
                                          marginLeft: "4px",
                                          fontSize: "0.875em",
                                        }}
                                      >
                                        Course Instructor
                                      </Typography>
                                    </Box>
                                  </Tooltip>
                                )}
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{ marginLeft: "auto" }}
                              >
                                <Typography
                                  style={{
                                    color: "#888",
                                    fontSize: "0.875em", // Smaller font size
                                    marginRight: "8px", // Add space between timestamp and icons
                                  }}
                                >
                                  {formatTimestamp(reply.timestamp)}
                                </Typography>

                                {/* if user is looking at their OWN reply, display Delete/Edit buttons */}
                                {reply.userId === userObject.uid && (
                                  <Box>
                                    <IconButton
                                      onClick={() =>
                                        handleEditReplyClick(
                                          question.id,
                                          reply.id,
                                          reply.content
                                        )
                                      }
                                      size="small"
                                      className="mui-icon"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteReply(question.id, reply.id)
                                      }
                                      size="small"
                                      className="mui-icon"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                )}
                              </Box>
                            </Box>

                            {editReplyId[question.id] === reply.id ? (
                              <Box>
                                <TextField
                                  label="Edit your reply"
                                  multiline
                                  rows={2}
                                  value={editReplyContent}
                                  onChange={(e) =>
                                    setEditReplyContent(e.target.value)
                                  }
                                  variant="outlined"
                                  fullWidth
                                />
                                <Box mt={1}>
                                  <IconButton
                                    className="mui-icon"
                                    onClick={() => {
                                      handleEditReply(
                                        question.id,
                                        reply.id,
                                        editReplyContent
                                      );
                                      setEditReplyId((prev) => ({
                                        ...prev,
                                        [question.id]: null,
                                      }));
                                    }}
                                    size="small"
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      setEditReplyId((prev) => ({
                                        ...prev,
                                        [question.id]: null,
                                      }))
                                    }
                                    size="small"
                                    className="mui-icon"
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            ) : (
                              <Typography variant="body2">
                                {reply.content}
                              </Typography>
                            )}
                          </Box>
                        ))}

                      {replyInputVisibility[question.id] && (
                        <Box>
                          <TextField
                            label="Write a reply"
                            multiline
                            rows={2}
                            value={newReply[question.id] || ""}
                            onChange={(e) =>
                              setNewReply((prev) => ({
                                ...prev,
                                [question.id]: e.target.value,
                              }))
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleAddReply(question.id)}
                                    edge="end"
                                    color="primary"
                                  >
                                    <SendIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default Forum;
