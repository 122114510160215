import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box, Zoom, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { getAllCourses, getCurrentUserObject } from "../helpers/source";
import CourseCard from "./CourseCard";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import SearchBar from "./SearchBar/SearchBar";
import PageTitleHeader from "../PageTitleHeader";
import { isAPharmacist } from "../helpers/helperFunctions";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function CoursesPreview() {
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const [courses, setCourses] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [notCompletedCourses, setNotCompletedCourses] = useState([]);
  const [inProgressCourses, setInProgressCourses] = useState([]);
  const [loggedOutCourses, setLoggedOutCourses] = useState([]);
  const [tutorial, setTutorial] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [courseCategoryFilter, setCourseCategoryFilter] = useState([]);

  //Ensure each section has its own unique ref for its scroll container
  const scrollContainerRefs = {
    getStarted: useRef(null),
    inProgress: useRef(null),
    exploreCourses: useRef(null),
    completedCourses: useRef(null),
  };

  //fetch all courses from db
  useEffect(() => {
    const fetchCourses = async () => {
      let coursesToDisplay = [];
      const coursesData = await getAllCourses();
      // sort courses by order tag (numeral). The greater the number, the earlier it is listed
      //(consider giving the newest course the highest number)
      const sortedCourses = coursesData.sort((a, b) => b.order - a.order);
      //filter to display only courses that don't have hideFromPublic enabled
      coursesToDisplay = sortedCourses.filter(
        (courseData) => courseData?.hideFromPublic !== true
      );
      setCourses(coursesToDisplay);
    };
    fetchCourses();
  }, []);

  const updateCourseFilter = (profession) => {
    if (isAPharmacist(profession)) {
      return setCourseCategoryFilter(["Pharmacist"]);
    } else {
      return setCourseCategoryFilter([profession]);
    }
  };

  useEffect(() => {
    const fetchUserCourses = async () => {
      if (currentUser) {
        // get user data
        const userObject = await getCurrentUserObject(currentUser.uid);

        updateCourseFilter(userObject?.profession);
        const completed = [];
        const notCompleted = [];
        const inProgress = [];
        // Categorize courses based on user's status
        courses.forEach((course) => {
          const userCourse = userObject.courses[course.courseId];
          const isPharmacyTechnician =
            userObject?.profession === "Pharmacy Technician";
          const isAccreditedCourse = course?.accredited;

          if (isPharmacyTechnician && isAccreditedCourse) {
            // hide accredited courses for Pharmacy Technician (since not official HCP)
            return;
          }

          if (
            course.tutorial &&
            !userCourse?.completed &&
            !userCourse?.enrolled
          ) {
            setTutorial(course);
          } else if (userCourse && userCourse.completed) {
            completed.push(course);
          } else if (
            userCourse &&
            !userCourse.completed &&
            userCourse.enrolled
          ) {
            inProgress.push(course);
          } else {
            notCompleted.push(course);
          }
        });
        setCompletedCourses(completed);
        setNotCompletedCourses(notCompleted);
        setInProgressCourses(inProgress);
      } else {
        const exploreCourses = [];
        courses.forEach((course) => {
          if (!course.tutorial) {
            // only add the course if it's not a tutorial
            exploreCourses.push(course);
          }
        });
        setLoggedOutCourses(exploreCourses);
      }
    };
    fetchUserCourses();
  }, [courses, currentUser]);

  // Check for small screens
  const isSmallScreen = window.innerWidth <= 1008;
  const sectionWidth = isSmallScreen ? "95%" : "100%";

  const scrollHandler = (direction, section) => {
    const scrollContainer = scrollContainerRefs[section].current;
    if (!scrollContainer) {
      console.log("Scroll container is not available.");
      return;
    }
    const distance = 300;
    if (direction === "left") {
      scrollContainer.scrollTo({
        left: scrollContainer.scrollLeft - distance,
        behavior: "smooth",
      });
    } else {
      scrollContainer.scrollTo({
        left: scrollContainer.scrollLeft + distance,
        behavior: "smooth",
      });
    }
  };

  const filterByCategoryChosen = (course) => {
    if (courseCategoryFilter.length === 0) {
      return true;
    } else {
      const standardizedArray = courseCategoryFilter?.map((item) =>
        item.toLowerCase()
      );
      return (
        course?.targetAudience?.some((targetAudience) =>
          standardizedArray?.includes(targetAudience?.toLowerCase())
        ) || course?.targetAudience?.includes("all")
      );
    }
  };

  const filterBySearchItem = (course) => {
    const courseName = course.courseName ? course.courseName.toLowerCase() : "";
    const description = course.description
      ? course.description.toLowerCase()
      : "";
    const category = course.category ? course.category.toLowerCase() : "";

    return (
      courseName.includes(searchQuery.toLowerCase()) ||
      description.includes(searchQuery.toLowerCase()) ||
      category.includes(searchQuery.toLowerCase())
    );
  };

  // filter courses based on search query + category
  const filterCourses = (coursesList) =>
    coursesList.filter(
      (course) => filterBySearchItem(course) && filterByCategoryChosen(course)
    );

  // function to check if any section has courses after filtering
  const doAnySectionsHaveCourses = () => {
    return (
      filterCourses(completedCourses).length > 0 ||
      filterCourses(notCompletedCourses).length > 0 ||
      filterCourses(inProgressCourses).length > 0 ||
      filterCourses(loggedOutCourses).length > 0 ||
      (tutorial && filterCourses([tutorial]).length > 0)
    );
  };

  const renderNoCoursesMessage = () => {
    if (!doAnySectionsHaveCourses()) {
      return (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="p">
            {searchQuery && `No courses found for "${searchQuery}".`} If you
            have specific course suggestions, please submit your request on our{" "}
          </Typography>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfsNLHr1rioRG6frfcDfLptotY-oLaQ_JB8oD_mgSPHNAkZPA/viewform"
            target="_blank"
            rel="noreferrer"
          >
            Course Suggestion Form
          </a>
          .
        </Box>
      );
    }
    return null;
  };

  // render course cards
  const renderCourseGrid = (filteredCourses) =>
    filteredCourses.map((course) => (
      <Zoom
        in={true}
        style={{ transitionDelay: "100ms" }}
        key={course.courseName}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "300px" },
            padding: 1,
            boxSizing: "border-box",
            flexShrink: 0,
          }}
        >
          <CourseCard {...course} />
        </Box>
      </Zoom>
    ));

  const arrowButtonStyles = {
    position: "absolute",
    top: "57.5%",
    transform: "translateY(-50%)",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "50%",
    zIndex: 2,
  };

  const renderCoursesSection = (coursesList, titleKey) => {
    const filteredCourses = filterCourses(coursesList);
    if (filteredCourses.length === 0) {
      return null; // do not render the section if no courses found
    }

    const translatedTitle = t(`coursesPreview.${titleKey}`);

    return (
      <>
        <Grid container>
          <h4 style={{ margin: "1em" }}>{translatedTitle}</h4>
        </Grid>

        <Box
          sx={{
            bgcolor: "white",
            padding: "2rem",
            borderRadius: "8px",
            width: sectionWidth,
            position: "relative",
          }}
        >
          <Box sx={{ position: "relative" }}>
            {filteredCourses.length >= 3 && (
              <>
                <IconButton
                  onClick={() => scrollHandler("left", titleKey)}
                  sx={{ ...arrowButtonStyles, left: "0" }}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
                <IconButton
                  onClick={() => scrollHandler("right", titleKey)}
                  sx={{ ...arrowButtonStyles, right: "0" }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </>
            )}
            <Box
              ref={scrollContainerRefs[titleKey]}
              sx={{
                display: "flex",
                overflowX: "auto",
                flexWrap: "nowrap",
                flexGrow: 1,
                position: "relative",
              }}
            >
              {renderCourseGrid(filteredCourses)}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <CssBaseline />

      <Container
        disableGutters={{ xs: true, xl: false }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: "1rem",
        }}
      >
        {currentUser && (
          <PageTitleHeader pageTitle={t("coursesPreview.myCourses")} />
        )}
        {currentUser && (
          <div className="filtering-container">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholderText={t("searchBar.placeholder")}
            />
          </div>
        )}

        {currentUser ? (
          <>
            {/* Tutorial */}
            {tutorial && renderCoursesSection([tutorial], "getStarted")}
            {inProgressCourses.length > 0 &&
              renderCoursesSection(inProgressCourses, "inProgress")}

            {notCompletedCourses.length > 0 &&
              renderCoursesSection(notCompletedCourses, "exploreCourses")}
            {/*  Completed Courses */}
            {completedCourses.length > 0 &&
              renderCoursesSection(completedCourses, "completedCourses")}
          </>
        ) : (
          <>{renderCoursesSection(loggedOutCourses, "exploreCourses")}</>
        )}
      </Container>
      {renderNoCoursesMessage()}
    </>
  );
}

export default CoursesPreview;
