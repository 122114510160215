import React, { useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { auth, db } from "../lib/firebase/config";
import { setDoc, doc, deleteDoc } from "firebase/firestore";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  //initial loading states, set to false after current user connected
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    auth.onAuthStateChanged(setCurrentUser);
  }, []);

  /** Creates a new user with the provided email and password using Firebase Authentication.
	If the sign up is successful, the user's email and UID are written to the "users" collection in Firestore. */
  const signUp = (
    email,
    password,
    firstName,
    lastName,
    licenseNumber,
    province,
    practiceType,
    company,
    profession,
    country,
    noLicenseReason
  ) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userRef = doc(db, "users", user.uid);
        const userData = {
          email: user.email,
          uid: user.uid,
          firstName: firstName,
          lastName: lastName,
          licenseNumber: licenseNumber,
          profession: profession,
          province: province,
          country: country,
          ...(practiceType && { practiceType: practiceType }),
          registrationDate: new Date().toISOString(), // add registration date
          ...(company && { company: company }), // add company if exists
          ...(noLicenseReason && { noLicenseReason: noLicenseReason }), // add no license reason if exists
          courses: {},
        };
        return setDoc(userRef, userData);
      })
      .catch((error) => {
        throw error;
      });
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = () => {
    return signOut(auth);
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  // reauthentication required before deleting account
  async function reauthenticateUser(email, password) {
    if (!email) return;
    const credential = EmailAuthProvider.credential(email, password);
    const userCredential = await reauthenticateWithCredential(
      currentUser,
      credential
    );
    //need clarification what this does
    setCurrentUser(userCredential.user);
    return userCredential.user;
  }

  // function to delete from authentication
  async function deleteUser(email, password) {
    const reauthenticatedUser = await reauthenticateUser(email, password);
    await deleteUserFromDb(reauthenticatedUser.uid);
    await reauthenticatedUser.delete();
  }

  // Function to delete a user from Firestore db
  function deleteUserFromDb(userId) {
    const userRef = doc(db, "users", userId);
    return deleteDoc(userRef);
  }
  const value = {
    currentUser,
    setCurrentUser,
    signUp,
    signIn,
    logOut,
    resetPassword,
    deleteUser,
    reauthenticateUser,
  };

  useEffect(() => {
    /* onAuthStateChanged is a firebase method to notify whenever user is set
		returns a method that will unsub to this listener  */
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  });

  return (
    <div>
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    </div>
  );
}
